import React, {useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {translateText} from 'services/translation'
import {getLanguageCode} from 'utils/localization/language'
import {getIsTranslationAvailable} from 'utils/translations'

import {Box} from '@daedalus/atlas/helpers/Box'
import {TruncatedContent} from '@daedalus/atlas/helpers/TruncatedContent'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {
  ReviewComment,
  ReviewCommentProps
} from '@daedalus/shared/src/search/ReviewComment'

import {ReviewText} from './ReviewText'
import {ReviewTranslation} from './ReviewTranslation'

export type TranslationState = 'original' | 'translating' | 'translated'

interface Props extends ReviewCommentProps {
  reviewText: string
  reviewLanguage: string
  truncatedContentLines?: number
  disableReadMore?: boolean
  searchKeywords?: string[]
}
export const ReviewCommentWrapper: React.FC<Props> = ({
  reviewText,
  reviewLanguage,
  truncatedContentLines = 5,
  disableReadMore = false,
  searchKeywords,
  ...props
}) => {
  const dispatchTeamEvent = useDispatchTeamEvent()

  const languageCode = useSelector(getLanguageCode)

  const [translationState, setTranslationState] =
    useState<TranslationState>('original')
  const [translatedText, setTranslatedText] = useState('')

  const isTranslationAvailable = useMemo(
    () => getIsTranslationAvailable(reviewLanguage, languageCode),
    [reviewLanguage, languageCode]
  )

  const onTranslateReview = () => {
    if (translatedText) {
      setTranslationState('translated')
      return
    }
    setTranslationState('translating')
    dispatchTeamEvent(
      trackEvent({
        category: Category.User,
        entity: Entity.ReviewsTranslate,
        action: Action.Clicked,
        payload: {
          originalLanguage: reviewLanguage,
          translatedLanguage: languageCode
        }
      })
    )
    translateText(reviewText, languageCode)
      .then(translation => {
        setTranslatedText(translation)
        setTranslationState('translated')
        dispatchTeamEvent(
          trackEvent({
            category: Category.System,
            entity: Entity.ReviewsTranslate,
            action: Action.Loaded,
            payload: {
              originalLanguage: reviewLanguage,
              translatedLanguage: languageCode
            }
          })
        )
      })
      .catch(() => {
        setTranslationState('original')
        dispatchTeamEvent(
          trackEvent({
            category: Category.System,
            entity: Entity.ReviewsTranslate,
            action: Action.Errored,
            payload: {
              originalLanguage: reviewLanguage,
              translatedLanguage: languageCode
            }
          })
        )
      })
  }
  return (
    <div>
      <ReviewComment {...props}>
        <TruncatedContent
          onReadMoreClick={disableReadMore ? () => null : undefined}
          lines={truncatedContentLines}
        >
          <ReviewText
            translationState={translationState}
            translatedText={translatedText}
            originalText={reviewText}
            searchKeywords={searchKeywords}
          />
        </TruncatedContent>
      </ReviewComment>
      {isTranslationAvailable && (
        <Box marginTop="s400">
          <ReviewTranslation
            translationState={translationState}
            onTranslate={onTranslateReview}
            onShowOriginal={() => setTranslationState('original')}
          />
        </Box>
      )}
    </div>
  )
}
